<template>
  <div class="tenant-group-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :title="$t('tenant_groups')"/>

          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <TenantGroupFilterForm :values="filters" ref="filterForm" slot="filter"/>
          </DataFilter>
          <br/>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <ActionCell
              :handle-edit="() => editHandler(props.item)"
              :handle-delete="() => deleteHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import TenantGroupFilterForm from '../../components/tenantGroup/Filter';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import ActionCell from '../../components/ActionCell';

export default {
  name: 'TenantGroupList',
  servicePrefix: 'TenantGroup',
  mixins: [ListMixin],
  components: {
    TenantGroupFilterForm,
    DataFilter,
    Toolbar,
    ActionCell,
  },
  data() {
    return {
      headers: [
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('roles'), value: 'roles'},
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false
        }
      ],
      selected: []
    };
  },
  computed: {
    ...mapGetters('tenantGroup', {
      items: 'list'
    }),
    ...mapFields('tenantGroup', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view'
    })
  },
  methods: {
    ...mapActions('tenantGroup', {
      getPage: 'fetchAll',
      deleteItem: 'del'
    })
  }
};
</script>
